/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Enable touch/active events on touchscreen devices
        document.addEventListener("touchstart", function() {},false);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        // Hide refer banner after 10 seconds
        var timeout;
        timeout = setTimeout(function() {
          $('.refer-banner').slideUp('500');
        }, 10000);
        $('.close-refer-banner').click(function() {
          $('.refer-banner').slideUp('0');
        });


        // Login Modal script
        $('#loginModal-login ul.login-tabs li.inactive a').click(function() {
          $('#loginModal-login ul.login-tabs li.inactive a').parent('li').siblings('li.active').toggleClass('active','inactive');
          $(this).parent('li').addClass('active');

          $('#loginModal-login .col-1').toggleClass('col-active col-inactive');
          $('#loginModal-login .col-2').toggleClass('col-inactive col-active');

        });

        // Mobile country select trigger
        $('.country-select-trigger').click(function() {
          $('.mobile-country-select').slideToggle();
        });

        // Mobile search box display trigger
        $('.mobile-search-toggle').click(function() {
          $('.mobile-search-bar').slideToggle();
        });


          // Init purchase modal
          // - Update subtotal amount when updating item quantities
          // - Toggle Add To Cart button
          function initPurchaseModalForm($qtyField) {
              if ($qtyField.length) {
                  var $overlay = $qtyField.closest('.deep-purchase-window');
                  if ($overlay.length) {
                      var updateTotalAmount = 0.00;
                      var totalItemCount = 0;
                      $overlay.find("input.qty:visible").each(function () {
                          var itemCount = parseInt($(this).val());
                          if (isNaN(itemCount)) {
                              itemCount = 0;
                          }
                          totalItemCount = totalItemCount + parseInt(itemCount);

                          var $row = $(this).closest('tr');
                          var amount = $row.find('.amount:visible:last').text();
                          var $freeAmountElement = $row.find('ins');
                          if ($freeAmountElement.length && $freeAmountElement.text().indexOf('Free') === 0) {
                              amount = 0;
                          }
                          if (amount) {
                              amount = parseFloat(amount.replace(/[^\d.-]/g, ""));
                              if (isNaN(parseInt(amount)) === false) {
                                  updateTotalAmount += itemCount * amount;
                              }
                          }
                      });
                      updateTotalAmount = updateTotalAmount.toFixed(2);
                      if (updateTotalAmount < 0) {
                          updateTotalAmount = '0.00';
                      }
                      $overlay.find('.subtotal .amount').text('$' + updateTotalAmount);

                      var $addToCartBtn = $overlay.find('.btn-add-to-cart button[type="submit"]');
                      if (totalItemCount > 0) {
                          $addToCartBtn.removeAttr('disabled').removeClass('btn--disabled');
                      } else {
                          $addToCartBtn.attr('disabled', 'disabled').addClass('btn--disabled');
                      }
                  }
              }
          }
          $('body').on('change', '.quantity input.qty', function() {
              initPurchaseModalForm($(this));
          });
          $('body').on('keyup', '.quantity input.qty', function() {
              initPurchaseModalForm($(this));
          });
          $('body').on('change', 'select[name=attribute_subscription-level], select[name=attribute_subscription-type]', function() {
              initPurchaseModalForm($(this));
          });
          // Fire on page load
          $('.deep-purchase-window').find('input.qty:first').trigger('keyup');

        /* OLD Use hoverIntent to open Products dropdown (.product-nav-wrapper) on main nav
        function slideOpen() {
          $('.product-nav-wrapper').stop(true,true).slideDown();
        }
        function slideClose() {
          if ($('.product-nav-wrapper:hover').length !==0) {
            // Leave .product-nav-wrapper open
          } else {
            // Close .product.nav-wrapper
            $('.product-nav-wrapper').slideUp();
          }
        }
        $('.menu-item-29, .menu-item-234').hoverIntent({
          over: slideOpen,
          out: slideClose,
          timeout: 500
        });
        // Close .product-nav-wrapper when cursor leaves the element
        $('.header-nav-wrapper').mouseleave(function() {
          $('.product-nav-wrapper').slideUp();
        });*/

        // New Products Mega Menu Function (works on click)
        $('.menu-item-1485, .menu-item-3056').click(function(){
          $('.product-nav-wrapper').slideDown();
        });
        // Close .product-nav-wrapper when cursor leaves the element
        $('.header-nav-wrapper').mouseleave(function() {
          $('.product-nav-wrapper').slideUp();
        });


        // ACF Tabbed content script
        var initTabBodyHeight = $('.acf-tabs-desktop-body').children('.current').height() + 30;
        $('.acf-tabs-desktop-body').css('height',initTabBodyHeight+'px');
        
        // Resize the height on window resize
        $(window).resize(function(){
          var initTabBodyHeight = $('.acf-tabs-desktop-body').children('.current').height() + 30;
          $('.acf-tabs-desktop-body').css('height',initTabBodyHeight+'px');
        });
        
        $('ul.acf-tabs-desktop li').click(function(){
          var tab_id = $(this).attr('data-tab');

          $('ul.acf-tabs-desktop li').removeClass('current');
          $('.acf-tabs-desktop-body .tab-body').removeClass('current');

          $(this).addClass('current');
          $("#"+tab_id).addClass('current');

          var tabBodyHeight = $('.acf-tabs-desktop-body').children('.current').height() + 30;
          $('.acf-tabs-desktop-body').css('height',tabBodyHeight+'px');
        });


        // ACF Accordion content script
        $('.acf-accordion li h1').click(function() {
          $(this).siblings('p').slideToggle();
          $(this).toggleClass('closed','');
        });
        $('.acf-accordion li p .inner-title').click(function() {
          $(this).toggleClass('closed','');
          $(this).next('.inner-body').toggleClass('closed','');
        });


        // Open Deep Purchase - Desktop
        $('.trigger-deep-purchase').click(function() {
          $(this).parent('.wrapper').next('.deep-purchase-overlay').addClass('show').find('input.qty:first').trigger('keyup').trigger('change');
          $(this).parent('.btn-wrapper').parent('.wrapper').next('.deep-purchase-overlay').addClass('show'); // Fix for Product Catalogue page, where there is an extra enclosing element which needs to be navigated to
          $('body').addClass('fixed');
          
          var deepPurchaseWindowHeight = $(this).parent('.wrapper').next('.deep-purchase-overlay').children('.deep-purchase-window').height();
          var dpInnerWrapHeight = deepPurchaseWindowHeight-100;
          if($(window).width() > 767){
            $(this).parent('.wrapper').next('.deep-purchase-overlay').children('.deep-purchase-window').children('.cart').children('.dp-inner-wrap').css('height',dpInnerWrapHeight+'px');
          }
        });


        // Handle Deep Purchase window resize
        $(window).resize(function() {
          // Set Max-Height on window resize
          var deepPurchaseOverlayHeight = $('.deep-purchase-overlay.show').height();
          var deepPurchaseWindowResizeHeight = deepPurchaseOverlayHeight-100;
          $('.deep-purchase-overlay.show').children('.deep-purchase-window').css('max-height',deepPurchaseWindowResizeHeight+'px');

          // Resize .dp-inner-wrap for fluid and max height
          var dpInnerWindow = deepPurchaseWindowResizeHeight/2;
          var dpTableHeight = $('.deep-purchase-overlay.show').children('.deep-purchase-window').children('.cart').children('.dp-inner-wrap').children('.group_table').height();
          var dpTableMaxResize = dpTableHeight+20;
          if($(window).width() > 767){
            $('.deep-purchase-overlay.show').children('.deep-purchase-window').children('.cart').children('.dp-inner-wrap').css('height',dpInnerWindow+'px');
            $('.deep-purchase-overlay.show').children('.deep-purchase-window').children('.cart').children('.dp-inner-wrap').css('max-height',dpTableMaxResize+'px');
          }
        });

        // Deep Purchase window close functions
        $('.deep-purchase-overlay .close-window').click(function() {
          $(this).parent().parent().parent('.deep-purchase-overlay').removeClass('show');
          $('body').removeClass('fixed');
        });

        // Close Modal on ESCAPE key pressed
        $(document).keydown(function(e) {
          if (e.keyCode === 27) {
            //$('.modal-overlay').fadeOut();
            $('.deep-purchase-overlay').removeClass('show');
            $('body').removeClass('fixed');
            $('.trigger-deep-purchase').removeClass('hidden');
          }
        });
        // Close Modal when clicking outside of Deep Purchase window
        $(".deep-purchase-overlay").mouseup(function(e) {
          var subject = $(".deep-purchase-window"); 

          if(e.target.class != subject.attr('class') && !subject.has(e.target).length) {
            $('.deep-purchase-overlay').removeClass('show');
            $('body').removeClass('fixed');
            $('.trigger-deep-purchase').removeClass('hidden');
          }
        });


        // Trigger Search Page Filter Section
        $('.search-refine-trigger').click(function(){
          $('.search-refine').slideToggle();
        });


        // Script for homepage tabbed content
        $('.home-core-tabs .slide #tab-1').addClass('current'); // Add selected class to first slide

        $('.home-core-tabs ul.tabs li').click(function(){
          var tab_id1 = $(this).attr('data-tab'); 

          $('.home-core-tabs ul.tabs li').removeClass('selected');
          $('.home-core-tabs .tab-body .slide.selected').removeClass('selected');

          $(this).addClass('selected');
          $('.home-core-tabs .tab-body #slide-'+tab_id1).addClass('selected');
          $('.home-core-tabs .slide.selected .tab-media #tab-1').addClass('current');
          $('.home-core-tabs .slide.selected .tab-media .image-tabs #tab-1').addClass('current');
          $('.home-core-tabs .slide.selected .tab-media .image-tabs li:first-child').addClass('current');
        });


        // Tabbed content script
        $('ul.image-tabs li').click(function(){
          var tab_id = $(this).attr('data-tab');

          $('ul.image-tabs li').removeClass('current');
          $('.image-tab-content').removeClass('current');

          $(this).addClass('current');
          // Add 'current' class to appropriate tab content container on homepage
          $('.slide.selected .tab-media #'+tab_id).addClass('current');
          // Add 'current' class to appropriate tab content container on all other pages
          $('.additional-info .images #'+tab_id).addClass('current');
        });

        // If the viewport is 768px and up, do this...
        if($(window).width() > 767){
          // Function to resize homepage top tabbed content (content tabs are absolute positioned and need jquery to alter height for responsiveness)
          //$('.product-type-tabs .tab-body .slide-wrapper').fadeIn();
          var selectedTabSlideHeight = $('.home-core-tabs .tab-body .container .slide-wrapper .slide.selected').height();
          var tabSlideBodyHeight = selectedTabSlideHeight+150;
          $('.home-core-tabs .tab-body').css('height',tabSlideBodyHeight+'px');
          // And the select new tab resize function...
          $('.home-core-tabs .container .tabs .tab').click(function() {
            var selectedTabSlideHeight = $('.home-core-tabs .tab-body .container .slide-wrapper .slide.selected').height();
            var tabSlideBodyHeight = selectedTabSlideHeight+150;
            $('.home-core-tabs .tab-body').css('height',tabSlideBodyHeight+'px');
          });
          // And the window resize function...
          $(window).resize(function(){
            var selectedTabSlideResizeHeight = $('.home-core-tabs .tab-body .container .slide-wrapper .slide.selected').height();
            var tabSlideBodyResizeHeight = selectedTabSlideResizeHeight+150;
            $('.home-core-tabs .tab-body').css('height',tabSlideBodyResizeHeight+'px');
          });
        }


        // Script for archive page Print/Digital Tabbed Components block
        $('.product-type-tabs .tabs .tab').first().addClass('selected'); // Add selected class to first tab
        $('.product-type-tabs .slide').first().addClass('selected'); // Add selected class to first slide
        $('.product-type-tabs ul.tabs li').click(function(){
          var tab_id2 = $(this).attr('data-tab');

          $('.product-type-tabs ul.tabs li').removeClass('selected');
          $('.product-type-tabs .tab-body .slide').removeClass('selected');

          $(this).addClass('selected');
          $('.product-type-tabs .tab-body #slide-'+tab_id2).addClass('selected');
        });


          // If the viewport is 768px and up, do this...
        if($(window).width() > 600){
          // Function to resize product blocks on product tabbed section (product blocks are absolute positioned and need jquery to alter height for responsiveness)
          $(window).load(function() {
            $('.product-type-tabs .tab-body .slide-wrapper').fadeIn();
                var selectedSlideHeight = $('.slide.selected').height();
                var tabBodyHeight = selectedSlideHeight+150;
                $('.product-type-tabs .tab-body').css('height',tabBodyHeight+'px');
                $(this).find('input.qty').val('0');
          });
          // And the select new tab resize function...
          $('.product-type-tabs .container .tabs .tab').click(function() {
            var selectedSlideHeight = $('.slide.selected').height();
            var tabBodyHeight = selectedSlideHeight+150;
            $('.product-type-tabs .tab-body').css('height',tabBodyHeight+'px');
          });
          // And the window resize function...
          $(window).resize(function(){
            var selectedSlideResizeHeight = $('.slide.selected').height();
            var tabBodyResizeHeight = selectedSlideResizeHeight+150;
            $('.product-type-tabs .tab-body').css('height',tabBodyResizeHeight+'px');
            $('.product-type-tabs .slide-wrapper').css('display','block');
          });

        }



        // Auto Hide Woocommerce Messages
        function fadein() {
          $('.woocommerce-message').fadeIn(500);
        }
        setTimeout(fadein, 100); 
        function fadeout() {
          $('.woocommerce-message').fadeOut(2000);
        }
        setTimeout(fadeout, 5000);

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.load-more-authors').click(function() {
          $('.team-member__hidden').fadeIn();
          $('.load-more-authors').fadeOut();
        });
      }
    },
    // woo commerce checkout page
    'checkout': {
      init: function() {
      },
      finalize: function() {
        // Toggle company fields based on radio selection
        $('input:radio[name="order_type_field"]').change(function() {
          var companyFieldList = '#billing_company_field, #purchase_order_field_field';
          if ($(this).val() === 'A school or company') {
            $(companyFieldList).show('slow');
          } else {
            $(companyFieldList).hide('fast');
          }
        });
        $('input:radio[name="order_type_field"]:checked').trigger('change');
      }
    },
    'contact_us': {
      init: function() {
      },
      finalize: function() {
        // Accordions for mobile offices and sales reps
        $('.acf-accordion-rep li h3').click(function() {
          $(this).siblings('.contact-rep-mobile').slideToggle().closest('li').toggleClass('closed', '');
        });
        $('.acf-accordion-office li h3').click(function() {
          $(this).siblings('.office').slideToggle().closest('li').toggleClass('closed', '');
        });

        // Handle the country's state's click events
        var $states = $('.map-state');
        $states.click(function() {
          selectState($(this).attr('id'));
        });

        var $stateLabels = $('.state-label');
        $stateLabels.click(function() {
          var state = $(this).attr('id').replace('label-', '');
          selectState(state);
        });

        function selectState(state) {
          // Adjust the map visually
          $states.css({fill: '#b3dcf0'});
          $('#'+state).css({fill: '#018fcf'});

          $stateLabels.css({fill: '#000000'});
          if (state != 'tas') {
            // tas label sits on a white bg so don't change it
            $('#label-' + state).css({fill: '#ffffff'});
          }

          // Toggle display of the sales rep info
          $('.sales-rep-state-block').hide();
          $('#sales-rep-state-'+state).show();
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
